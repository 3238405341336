/* global window */
/*eslint sort-imports:0*/

import PackagesUtil from 'santa-main-r/lib/lib/common/PackagesUtil.js'
import addExperimentsFromQuery from 'santa-main-r/lib/lib/common/addExperimentsFromQuery.js'
import {getBaseVersion} from 'santa-main-r/lib/lib/common/getBaseVersion'
import experimentFactory from 'santa-main-r/lib/lib/common/experimentFactory.js'
import getFullRjsConfig from 'santa-main-r/lib/lib/common/getFullRjsConfig.js'
import getQueryUtils from 'santa-main-r/lib/lib/common/getQueryUtils.js'
import getSubdomain from 'santa-main-r/lib/lib/common/getSubdomain.js'
import identifyForFullStory from 'santa-main-r/lib/lib/editor/identifyForFullStory.js'
import instrument from 'santa-main-r/lib/lib/editor/instrument.js'
import isLocal from 'santa-main-r/lib/lib/common/isLocal.js'
import joinURL from 'santa-main-r/lib/lib/common/joinURL.js'
import overrideScriptsLocationMapFromQuery from 'santa-main-r/lib/lib/common/overrideScriptsLocationMapFromQuery.js'
import requirejs from 'requirejs'
import rjsConfig from 'santa-main-r/lib/lib/editor/rjs-config.js'
import runEditor from 'santa-main-r/lib/lib/editor/runEditor.js'
import storageUtil from 'santa-main-r/lib/lib/common/storageUtil.js'

const {versions} = require('../../../gen/options.json')
const packages = require('../../../gen/packagesList.json')

const queryUtil = getQueryUtils(window)

window.define('experiment', [], () => experimentFactory.build(window))

window.joinURL = joinURL
window.queryUtil = queryUtil
window.persistent = storageUtil(window) // TODO: how is this used?
window.identifyForFullStory = identifyForFullStory

if (window.customRenderSite !== undefined) { // allows to override this in other html files as starting points
    window.customRenderSite()
} else {
    render()
}


function render() {
    const local = isLocal(queryUtil.getParameterByName('EditorSource'))
    const getEditorRjsConfig = rjsConfig(window, versions, local)
    const packagesUtil = new PackagesUtil(packages, window.location.search)

    window.mainLoaded = Date.now()

    window.serviceTopology.scriptsLocationMap = overrideScriptsLocationMapFromQuery(window.serviceTopology.scriptsLocationMap, queryUtil.getParameterByName('scriptsLocations'))

    const editorModel = window.editorModel
    if (editorModel) {
        editorModel.runningExperiments = addExperimentsFromQuery(editorModel.runningExperiments, queryUtil, 'editor')
        editorModel.languageCode = queryUtil.getParameterByName('lang') || editorModel.languageCode
    }

    const config = getFullRjsConfig(
        'santa-editor',
        window.editorBase,
        getEditorRjsConfig(
            window.serviceTopology,
            editorModel,
            window.santaBase,
            PackagesUtil
        ),
        packagesUtil,
        window.serviceTopology
    )

    if (editorModel) {
        editorModel.editorBase = config.baseUrl
        editorModel.editorVersion = getBaseVersion(config.baseUrl)

        instrument(window, requirejs, queryUtil, editorModel)
        window.bi.event(458, { // main-r loaded event
            prefetch: window.afterEditorPrefetch
        })
    } else {
        instrument(window, requirejs, queryUtil)
    }

    requirejs.config(config)

    try {
        window.document.domain = getSubdomain(window.document.domain)
    } catch (e) {
        // empty
    }

    if (isLocal(window.editorBase) && queryUtil.getParameterByName('hot') !== 'false') {
        requirejs([
            'hot',
            'zepto'
        ], (
            hot,
            $
        ) => {
            hot.init($, false)
            runEditor(window, packages, requirejs, editorModel, queryUtil, config)
        })
    } else {
        runEditor(window, packages, requirejs, editorModel, queryUtil, config)
    }
}
